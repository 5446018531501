import React from 'react';
import Layout from '@layouts';
import { Banners, SEO, About } from '@components';
import HomeVideo from '../popups/HomeVideo';

const { Banner } = Banners;

const Index = () => (
  <Layout
    bannerChildren={
      <Banner
        title='About Us'
        description='Learn more about our mission, vision, values, pillars, and origin story.'
      />
    }
  >
    <SEO
      title='ABOUT US'
      description='Learn more about our mission, vision, values, pillars, and origin story.'
    />

    <About />
    <HomeVideo />
  </Layout>
);

export default Index;
